<template>
  <NavigationBar />
  <RouterView />
  <BottomFooter />
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import BottomFooter from './components/BottomFooter.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    BottomFooter,
  }
}
</script>

<style>
@font-face {
  font-family: 'Baloo';
  src: url('./assets/fonts/baloo/Baloo-Regular.ttf');
}
@font-face {
  font-family: 'Chewy';
  src: url('./assets/fonts/Chewy/Chewy-Regular.ttf');
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Bold Poppins';
  src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf');
}
@font-face {
  font-family: 'Bunny Ears';
  src: url('./assets/fonts/BunnyEars/BunnyEars-R7rE.ttf');
}
@font-face {
  font-family: 'Lobster';
  src: url('./assets/fonts/Lobster/Lobster-Regular.ttf');
}


#app {
  font-family: 'Chewy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
