<template>
    <footer id="footer">
        <div id="footer-container">
            <div id="footer-links-container">
                <RouterLink v-for="link in footerLinks" :key="link" :to="link.path" @click="resetNavLink" class="footer-link">{{ link.name }}</RouterLink>
            </div>
            <p id="copyright">Copyright © 2024 Fabulas Zauberwelt. Alle Rechte vorbehalten.</p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "BottomFooter",
        data() {
            return {
                footerLinks: [{ name: "Impressum", path: "/impressum" }, { name: "Datenschutzerklärung", path: "/datenschutz" }, { name: "AGB", path: "/geschaeftsbedingungen" }, 
                { name: "Home", path: "/" }]
            }
        },
        methods: {
            resetNavLink() {
                this.$store.commit("setActiveLink", {});
            }
        }
    }

</script>

<style>
    #footer {
        display: flex;
        flex-direction: column;
        background-color: #282828;
        justify-content: center;
        align-items: center;
        padding: 2rem;
    }
    #footer-links-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }
    .footer-link {
        margin: 1.2rem;
        text-decoration: none;
        color: white;
        position: relative;
        font-family: 'Poppins', sans-serif;
    }
    .footer-link::after {
        content: "";
        position: absolute;
        background-color: white;
        height: 0.1rem;
        width: 0;
        left: 0;
        bottom: -0.3rem;
        transition: 0.3s;
    }
    .footer-link:hover {
        color: white;
    }
    .footer-link:hover::after {
        width: 100%;
    }
    #copyright {
        color: rgb(216, 216, 216);
        font-weight: 400;
        font-size: .8rem;
        margin-top: 2.5rem;
        font-family: 'Baloo', sans-serif;
    }
    @media only screen and (max-width: 576px) {
        #footer-links-container {
            display: flex;
            flex-direction: column;
        }
        .footer-link {
            margin: .3rem;
        }
        #copyright {
            margin-top: 1.5rem;
        }
    }
</style>