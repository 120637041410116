import Vuex from 'vuex'

export default new Vuex.Store({
    state: {
        activeLink: { name: "Start", path: "/" },
        showModal: true,
    },
    mutations: {
        setActiveLink(state, link) {
            state.activeLink = link;
        },
        setShowModal(state, value) {
            state.showModal = value;
        }
    },
})