<template>
    <section id="contact-page">
        <article id="contact-container" style="height:600px; width:60%">
            <h1 id="contact-title">Kontaktiere uns<font-awesome-icon class="icon" icon="fa-solid fa-mobile-phone"/></h1>
            <div id="map-container">
                <OpenStreetMap />
            </div>
            <div id="contact-button-container">
                <button id="contact-button-whatsapp">
                    <a href="https://wa.me/message/D6UQOAYXZFBYJ1"><font-awesome-icon class="contact-icons" icon="fa-brands fa-whatsapp"/></a>
                </button>
                <button id="contact-button-instagram">
                    <a href="https://www.instagram.com/fabulaszauberwelt/"><font-awesome-icon class="contact-icons" icon="fa-brands fa-instagram"/></a>
                </button>
            </div>
            <div id="call-text-container">
                <p id="call-text">Oder rufe uns einfach direkt an unter: <a href="tel:+496061705839" id="number">+49 6061 705839</a></p>
            </div>
        </article>
    </section>
</template>

<script>
import OpenStreetMap from '@/components/OpenStreetMap.vue';
import anime from 'animejs';

export default {
    name: "ContactPage",
    components: {
        OpenStreetMap,
    },
    mounted() {
        anime({
            targets: '#contact-title',
            opacity: [0, 1],
            duration: 500,
            easing: 'easeInQuad',
        })
        anime({
            targets: '#contact-button-container',
            opacity: [0, 1],
            delay: 500,
            duration: 500,
            easing: 'easeInQuad',
        })
        anime({
            targets: '#call-text',
            opacity: [0, 1],
            delay: 1000,
            duration: 500,
            easing: 'easeInQuad',
        })
    }
}
</script>

<style>
    #contact-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #contact-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 100%;
        height: 100%;
    }
    #contact-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1rem;
        padding-top: 1.2rem;
    }
    #map-container {
        width: 95%;
        height: 100%;
        z-index: 1;
    }
    #contact-button-container {
        margin: 2rem 0 2rem 0;
    }
    #contact-button-whatsapp {
        border-radius: 100%;
        border: none;
        height: 5rem;
        width: 5rem;
        font-size: 2rem;
        margin-right: 3rem;
        cursor: pointer;
        background-color: #25D366;
    }
    #contact-button-whatsapp:hover{
        transform: translateY(-8px);
        transition: 0.3s ease-in-out;
    }
    #contact-button-instagram {
        border-radius: 100%;
        border: none;
        height: 5rem;
        width: 5rem;
        font-size: 2rem;
        cursor: pointer;
        background-color: #E4405F;
    }
    #contact-button-instagram:hover{
        transform: translateY(-8px);
        transition: 0.2s all ease-in-out;
    }
    .contact-icons {
        color: white;
    }
    #call-text-container {
        margin: 0 0 1rem 0;
    }
    #call-text {
        font-size: 1.2rem;
        font-family: 'Poppins', sans-serif;
        font-style: italic;
    }
    #number {
        color: #9900CC;
    }
    @media only screen and (max-width: 768px) {
        #contact-container {
            width: 90% !important;
        }
    }
    @media only screen and (max-width: 576px) {
        #contact-container {
            width: 100% !important;
        }
        #contact-button-whatsapp {
            margin: 0 1rem 0 0;
        }
        #call-text > a {
            white-space: nowrap;
        }
        #contact-title {
            font-size: 2.1rem;
        }
    }
</style>