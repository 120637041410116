<template>
    <nav id="nav-bar">
        <div id="image-container">
            <a href="/">
                <img id="logo" src="../assets/logo.png" alt="Fabulas Zauberwelt Logo" />
            </a>
        </div>
        <ul id="nav-menu" v-bind:class="isActiveClass">
            <li v-for="link in navLinks" :key="link" class="nav-item">
                <a
                    :href="link.path"
                    @click.prevent="setActiveLink(link)"
                    :class="{ 'active-link': link.path === activeLink.path }"
                    class="nav-link"
                    rel="canonical"
                >
                    {{ link.name }}
                </a>
            </li>
        </ul>
        
        <!-- for mobile version -->
        <div id="hamburger-menu" @click="toggleMenu" v-bind:class="isActiveClass" >
            <span class="hamburger-bar"></span>
            <span class="hamburger-bar"></span>
            <span class="hamburger-bar"></span>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "NavigationBar",
        data() {
            return {
                navLinks: [{ name: "Über uns", path: "/ueber-uns" }, { name: "Preise & Zeiten", path: "/preise-zeiten" }, 
                { name: "Geburtstage", path: "/geburtstage" }, { name: "Gruppen", path: "/gruppen" }, 
                { name: "Attraktionen", path: "/attraktionen" }, { name: "Kontakt", path: "/kontakt" }],
                isActiveClass: "",
            }
        },
        methods: {
            toggleMenu() {
                this.isActiveClass === "active" ? this.isActiveClass = "" : this.isActiveClass = "active";
            },
            setActiveLink(link) {
                this.$store.commit("setActiveLink", link);
                this.isActiveClass = "";
                this.$router.push(link.path);
            }
        },
        computed: {
            activeLink() {
                return this.$store.state.activeLink;
            }
        } 
    }
</script>

<style>
    #nav-bar {
        top: 0;
        display: flex;
        flex-direction: row;
        position: fixed;
        justify-content: center;
        align-items: center;
        background-color: #0077B6;
        width: 100%;
        height: 6rem;
        z-index: 100;
    }
    #nav-menu {
        display: flex;
        align-items: center;
    }
    #image-container {
        display: flex;
        position: absolute;
        left: -30px;
        align-items: center;
        justify-content: left;
    }
    #logo {
        width: 60%;
    }
    .nav-item {
        list-style: none;
        margin: 1.2rem;
    }
    .nav-link {
        text-decoration: none;
        font-size: 1.1rem;
        position: relative;
        letter-spacing: 1px;
        color: #F5F5F5;
        font-weight: 600;
        transition: background-color 0.3 ease;
        padding: 2.2rem 1.1rem 2.2rem;
        font-family: 'Baloo', sans-serif;
    }
    .nav-link:hover {
        background-color: #FFD700;
        color: #0077B6;
    }
    #hamburger-menu {
        display: none;
    }
    .hamburger-bar {
        display: block;
        width: 1.5rem;
        height: 0.188rem;
        margin: 0.313rem;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: #000;
    }
    .active-link {
        color: #FFD700;
    }

    /* for mobile view */
    @media only screen and (max-width: 1320px) {
        #nav-menu {
            display: flex;
            position: fixed;
            flex-direction: column;
            background-color: #0077B6;
            top: -200%;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: 1s;
            margin: 0;
            justify-content: center;
            align-items: center;
        }
        #nav-menu.active {
            top: 0;
        }
        #hamburger-menu {
            display: block;
            cursor: pointer;
            padding-left: 80%;
        }
        #hamburger-menu.active .hamburger-bar:nth-child(2) {
            opacity: 0;
        }
    
        #hamburger-menu.active .hamburger-bar:nth-child(1) {
            transform: translateY(8px) rotate(45deg);
        }
    
        #hamburger-menu.active .hamburger-bar:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
        }
        .nav-link {
            padding: 0;
            font-size: 1.5rem;
        }
        .nav-link:hover {
            background-color: transparent;
            color: #F5F5F5;
        }
    }
    @media only screen and (max-width: 576px) {
        #image-container {
            left: -60px;
        }
        #logo {
            width: 55%;
            height: 55%;
        }
    }
</style>