import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faClock, faEuro, faUserClock, faBirthdayCake, faPeopleGroup, faMobilePhone, faChildren, faArrowUp, faGift, faClose } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

library.add(faClock, faEuro, faUserClock, faBirthdayCake, faPeopleGroup, faInstagram, faMobilePhone, faWhatsapp, faChildren, faArrowUp, faGift, faClose)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(store).use(vuetify).mount('#app')
