<template>
    <section id="attraction-page">
        <article id="attraction-container">
            <h1 id="attraction-title">Attraktionen<font-awesome-icon class="icon" icon="fa-solid fa-children"/></h1>
            <div id="picture-container">
                <div 
                    v-for="image in images" 
                    :key="image"
                    data-aos="fade-up" 
                    data-aos-duration="1000" 
                    data-aos-delay="200"
                >
                    <h2 class="picture-title">{{ image.title }}</h2>
                    <img class="picture" loading="lazy" :src="image.src" :alt="image.alt" />
                </div>
            </div>
        </article>
        <ScrollToTop />
    </section>
</template>

<script>
import ScrollToTop from '@/components/ScrollToTop.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

    export default {
        name: "AttractionPage",
        components: {
            ScrollToTop,
        },
        data() {
            return {
                images: [ { src: require('../assets/attractions/Flugzeug.jpeg'), title: 'Flugzeug', alt: 'Flugzeug' }, { src: require('../assets/attractions/AirHockey.jpeg'), title: 'Air Hockey', alt: 'Air Hockey' }, 
                    { src: require('../assets/attractions/Klettergeruest.jpg'), title: 'Klettergerüst', alt: 'Klettergerüst' }, { src: require('../assets/attractions/Baellebad.jpeg'), title: 'Bällebad', alt: 'Bällebad' }, 
                    { src: require('../assets/attractions/BaelleBagger.jpeg'), title: 'Bälle Bagger', alt: 'Bälle Bagger' }, { src: require('../assets/attractions/Boxautomat.jpeg'), title: 'Boxautomat', alt: 'Boxautomat' },
                    { src: require('../assets/attractions/Fussballplatz.jpeg'), title: 'Fußballplatz', alt: 'Fußballplatz' }, { src: require('../assets/attractions/Greifautomat.jpeg'), title: 'Greifautomat', alt: 'Greifautomat' }, 
                    { src: require('../assets/attractions/Haengematte.jpeg'), title: 'Hängeschaukel', alt: 'Hängeschaukel' }, { src: require('../assets/attractions/Kletterschiff.jpeg'), title: 'Kletterschiff', alt: 'Kletterschiff' }, 
                    { src: require('../assets/attractions/Labyrinth.jpeg'), title: 'Gespensterhöhle', alt: 'Gespensterhöhle' }, { src: require('../assets/attractions/Sitztische.jpeg'), title: 'Sitztische', alt: 'Sitztische' },
                    { src: require('../assets/attractions/Sitzzelte.jpeg'), title: 'Tipi-Zelte', alt: 'Tipi-Zelte' }, { src: require('../assets/attractions/Spielautomat.jpeg'), title: 'Ghost Spielautomat', alt: 'Ghost Spielautomat' },
                    { src: require('../assets/attractions/Trampoline.jpeg'), title: 'Trampoline', alt: 'Trampoline' }, { src: require('../assets/attractions/Geburtstagstisch1.jpeg'), title: 'Geburtstagstisch', alt: 'Geburtstagstisch' },
                    { src: require('../assets/attractions/Geburtstagstisch2.jpeg'), title: 'Geburtstagstisch', alt: 'Geburtstagstisch' }, { src: require('../assets/attractions/Geburtstagstisch3.jpeg'), title: 'Geburtstagstisch', alt: 'Geburtstagstisch' },
                ],
            }
        },
        mounted() {
            AOS.init({
                offset: 200,
                duration: 700,
            });
        }
    }
</script>

<style>
    #attraction-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #attraction-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        height: 100%;
    }
    #attraction-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1.5rem;
        padding-top: 1.2rem;
    }
    #picture-container {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
    }
    .picture-title {
        font-size: 2rem;
        margin-bottom: .5rem;
        letter-spacing: 2px;
    }
    .picture {
        width: 300px;
        height: 300px;
        flex-basis: calc(33.33% - 4rem);
        margin: 0 1.5rem 1.5rem 1.5rem;
        border: 6px solid white;
    }
    @media only screen and (max-width: 768px) {
        #attraction-container {
            width: 90%;
        }
    }
    @media only screen and (max-width: 576px) {
        #attraction-container {
            width: 100%;
        }
    }
</style>