import { createRouter, createWebHistory } from "vue-router";
import StartPage from "@/pages/StartPage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import PriceTimePage from "@/pages/PriceTimePage.vue";
import BirthdayPage from "@/pages/BirthdayPage.vue";
import GroupPage from "@/pages/GroupPage.vue";
import AttractionPage from "@/pages/AttractionPage.vue";
import ContactPage from "@/pages/ContactPage.vue";
import ConditionPage from "@/pages/FooterPages/ConditionPage.vue";
import ImprintPage from "@/pages/FooterPages/ImprintPage.vue";
import DataPrivacyPage from "@/pages/FooterPages/DataPrivacyPage.vue";
import UnknownPage from "@/pages/UnknownPage.vue";

const routes = [
    {
        path: "/",
        name: "StartPage",
        component: StartPage,
    },
    {
        path: "/ueber-uns",
        name: "AboutPage",
        component: AboutPage,
    },
    {
        path: "/preise-zeiten",
        name: "PriceTimePage",
        component: PriceTimePage,
    },
    {
        path: "/geburtstage",
        name: "BirthdayPage",
        component: BirthdayPage,
    },
    {
        path: "/gruppen",
        name: "GroupPage",
        component: GroupPage,
    },
    {
        path: "/attraktionen",
        name: "AttractionPage",
        component: AttractionPage,
    },
    {
        path: "/kontakt",
        name: "ContactPage",
        component: ContactPage,
    },
    {
        path: "/impressum",
        name: "ImprintPage",
        component: ImprintPage,
    },
    {
        path: "/datenschutz",
        name: "DataPrivacyPage",
        component: DataPrivacyPage,
    },
    {
        path: "/geschaeftsbedingungen",
        name: "ConditionPage",
        component: ConditionPage,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "UnknownPage",
        component: UnknownPage,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

export default router;