<template>
    <v-row justify="center" align="center">
        <v-dialog v-model="showModal" width="90%" class="overlay" transition="dialog-bottom-transition">
            <v-toolbar id="toolbar">
                <font-awesome-icon class="close-icon" icon="fa-solid fa-close" @click="showModal = false" />
            </v-toolbar>
            <v-card id="modal-header">
                <h1 id="modal-title">
                    Fabulas geht in die Sommerpause ☀️
                </h1>
                <p class="modal-text">
                    Wir sind in der Sommerpause vom 01.05.2024 bis zum 31.08.2024 und in der Zeit <span id="underline-closed">geschlossen</span>.
                </p>
                <p class="modal-text">
                    Wir freuen uns auf Euch!
                </p>
                <v-card-actions id="modal-button-container">
                    <v-spacer></v-spacer>
                    <button id="modal-button-reservation">
                        <RouterLink to="/kontakt" @click="setActiveLink" id="modal-button-reservation-link">
                            Jetzt reservieren
                        </RouterLink>
                    </button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: 'InfoModalChristmas',
    data() {
        return {
            showModal: true,
        }
    },
    methods: {
        setActiveLink() {
            this.$store.commit("setActiveLink", { name: "Kontakt", path: "/kontakt" });
        }
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Festive&display=swap');

.overlay {
    display: flex;
    justify-content: center;
    align-items: center ;
    background-color: rgba(150, 150, 150, 0.8);
}

#toolbar {
    background-color: #40E0D0;
    width: 100%;
}

#modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url('../assets/summer.png');
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}

#modal-title {
    font-size: 4rem;
    color:  #FFD700;
    letter-spacing: 2px;
    width: 90%;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: 'Baloo', sans-serif;
}

.modal-text {
    font-size: 2rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
    width: 80%;
}

.modal-text:last-of-type {
    margin-top: 1.5rem;
    font-style: italic;
    font-size: 1.8rem;
}

#underline-closed {
    text-decoration: underline;
}

#modal-button-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

#modal-button-reservation {
    font-size: 1.8rem;
    font-family: 'Baloo', sans-serif;
    animation: beat 1.5s infinite;
    background-color: #E6E6FA;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes beat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

#modal-button-reservation-link {
    text-decoration: none;
    color: #000;
    padding: 1rem;
}

.close-icon {
    color: white;
    margin-left: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    #modal-title {
        font-size: 3rem;
        width: 100%;
    }
    .modal-text {
        font-size: 1.7rem;
        width: 80%;
    }
    .modal-text:last-of-type {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 576px) {
    #modal-title {
        font-size: 2.9rem;
        width: 100%;
    }
    .modal-text {
        font-size: 1.5rem;
        width: 80%;
    }
    .modal-text:last-of-type {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 480px) {
    #modal-title {
        font-size: 2rem;
    }
    .modal-text {
        font-size: 1.3rem;
    }
    .modal-text:last-of-type {
        font-size: 1.1rem;
    }
    #modal-button-reservation {
        font-size: 1.5rem;
    }
}
</style>