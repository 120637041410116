<template>
    <section id="data-privacy-page">
        <article id="data-privacy-container">
            <h1 id="data-privacy-title">Datenschutzerklärung</h1>
            <div>
                <h2>Allgemeiner Hinweis und Pflichtinformationen</h2>
                <div class="dp-text-container">
                    <h3>Benennung der verantwortlichen Stelle</h3>
                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                    <p>
                        <span>Fabulas Zauberwelt</span><br>
                        <span>Andrej Adler</span><br>
                        <span>Unterer Hammer 9</span><br>
                        <span>64720 </span> 
                        <span>Michelstadt</span>
                    </p>
                    <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
                </div>
                <div class="dp-text-container">
                    <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                    <p>
                        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. 
                        Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. 
                        Für den Widerruf genügt eine formlose Mitteilung per E-Mail. 
                        Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h3>
                    <p>
                        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. 
                        Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. 
                        Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: 
                        <a class="link" href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>Recht auf Datenübertragbarkeit</h3>
                    <p>
                        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. 
                        Die Bereitstellung erfolgt in einem maschinenlesbaren Format. 
                        Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h3>
                    <p>
                        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. 
                        Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                    <p>
                        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. 
                        TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. 
                        Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
                    </p>
                </div>
            </div>
            <div>
                <h2>Google Web Fonts</h2>
                <div class="dp-text-container">
                    <h3>Art und Umfang der Verarbeitung</h3>
                    <p>
                        Wir verwenden Google Fonts von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, als Dienst zur Bereitstellung von Schriftarten für unser Onlineangebot. 
                        Um diese Schriftarten zu beziehen, stellen Sie eine Verbindung zu Servern von Google Ireland Limited her, wobei Ihre IP-Adresse übertragen wird.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>Zweck und Rechtsgrundlage</h3>
                    <p>Der Einsatz von Google Fonts erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.</p>
                    <p>
                        Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. 
                        In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. 
                        Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. 
                        Eine Kopie dieser Standardvertragsklauseln können Sie unter 
                        <a class="link" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.
                    </p>
                    <p>
                        Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. 
                        Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>Speicherdauer</h3>
                    <p>
                        Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. 
                        Weitere Hinweise finden Sie in der Datenschutzerklärung für Google Fonts: 
                        <a class="link" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                    </p>
                </div>
            </div>
            <div>
                <h2>Font Awesome</h2>
                <div class="dp-text-container">
                    <h3>Art und Umfang der Verarbeitung</h3>
                    <p>Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer Website Font Awesome des Anbieters Fonticons, Inc..</p>
                </div>
                <div class="dp-text-container">
                    <h3>Zweck und Rechtsgrundlage</h3>
                    <p>Der Einsatz von Font Awesome erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.</p>
                    <p>
                        Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. 
                        In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. 
                        Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. 
                        Eine Kopie dieser Standardvertragsklauseln können Sie unter 
                        <a class="link" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.
                    </p>
                    <p>
                        Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. 
                        Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.
                    </p>
                </div>
                <div class="dp-text-container">
                    <h3>Speicherdauer</h3>
                    <p>
                        Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Fonticons, Inc. bestimmt. 
                        Weitere Hinweise finden Sie in der Datenschutzerklärung für Font Awesome CDN: 
                        <a class="link" href="https://cdn.fontawesome.com/privacy">https://cdn.fontawesome.com/privacy</a>.
                    </p>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
    export default {
        name: "DataPrivacyPage",
    }
</script>

<style>
    #data-privacy-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background: linear-gradient(to bottom, #87CEEB, #B9DFF1);
        justify-content: center;
        align-items: center;
    }
    #data-privacy-container {
        display: flex;
        flex-direction: column;
        margin-top: 7rem;
        margin-bottom: 1rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        font-family: 'Poppins', sans-serif;
    }
    #data-privacy-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1rem;
        padding-top: 1.2rem;
        font-family: 'Chewy', sans-serif;
        word-break: break-all;
    }
    .dp-text-container {
        padding: 0 3rem 0 3rem;
        margin-top: 2rem;
        overflow: hidden;
    }
    .dp-text-container:last-of-type {
        margin-bottom: 2rem;
    }
    .link {
        word-break: break-all;
    }
    @media only screen and (max-width: 768px) {
        #data-privacy-container {
            width: 90%;
        }
    }
    @media only screen and (max-width: 576px) {
        #data-privacy-container {
            width: 100%;
        }
        #data-privacy-title {
            font-size: 1.9rem;
        }
    }
</style>