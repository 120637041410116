<template>
    <InfoModalSommer />
    <section id="start-page">
        <article id="start-container">
            <h1 id="start-text">Willkommen bei <span class="word-highlight">Fabulas Zauberwelt</span></h1>
            <p id="start-subtext">Großes Indoor-Abenteuerland, für kleine Entdecker!</p>
            <ImageSlider />
            <div id="start-button-container">
                <button id="start-button"><RouterLink id="start-link" to="/attraktionen" @click="setActiveLink">Erkunde Fabulas</RouterLink></button>
            </div>
        </article>
    </section>
</template>

<script>
import ImageSlider from '@/components/ImageSlider.vue';
import InfoModalSommer from '@/components/InfoModalSommer.vue';
import anime from 'animejs';

    export default {
        name: "StartPage",
        components: {
            ImageSlider,
            InfoModalSommer,
        },
        methods: {
            setActiveLink() {
                this.$store.commit("setActiveLink", { name: "Attraktionen", path: "/attraktionen" });
            }
        },
        mounted() {
            anime({
                targets: '#start-text',
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 1000,
                easing: 'easeOutQuad',
            });
            anime({
                targets: '#start-subtext',
                opacity: [0, 1],
                translateY: [20, 0],
                duration: 1000,
                delay: 500, 
                easing: 'easeOutQuad',
            });
            anime({
                targets: '#start-button',
                translateX: [2000, 0],
                duration: 1000,
                delay: 500,
                easing: 'easeOutQuad', 
            })
        }
    }

</script>

<style>
    #start-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #start-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        height: 100%;
    }
    #start-text {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 3rem;
        margin-bottom: 0;
        padding-top: 1.2rem;
    }
    #start-subtext {
        margin-top: 0;
        font-weight: 200;
        font-size: 1.5rem;
        font-family: 'Bold Poppins', sans-serif;
    }
    .word-highlight {
        color: #008000;
        font-weight: 600;
    }
    #start-button-container {
        overflow: hidden;
        width: 100%;
    }
    #start-button {
        width: 25%;
        text-align: center;
        margin: 3rem;
        height: 4rem;
        text-transform: uppercase;
        background-color: #0077B6;
        cursor: pointer;
        font-family: 'Baloo', sans-serif;
        font-size: 1.3rem;
        border: none;
    }
    #start-button:hover {
        scale: 1.1;
        transition: 0.3s ease-in-out;
    }
    #start-link {
        text-decoration: none;
        color: #000;
    }
    @media only screen and (max-width: 768px) {
        #start-text {
            font-size: 2.5rem;
            margin: 2rem 2rem 1rem 2rem;
        }
        #start-subtext {
            font-size: 1.5rem;
            margin: 0 2rem 0 2rem;
        }
        #start-button {
            width: 40%;
        }
        #start-container {
            width: 95%;
        }
    }
    @media only screen and (max-width: 576px) {
        #start-text {
            font-size: 2.2rem;
            margin: 2rem 2rem 1rem 2rem;
        }
        #start-subtext {
            font-size: 1.3rem;
            margin: 0 2rem 0 2rem;
        }
        #start-button {
            width: 50%;
        }
        #start-container {
            width: 100%;
        }
    }
</style>