<template>
    <section id="price-time-page">
        <article class="price-time-container">
            <h1 class="price-time-title">Öffnungszeiten *<font-awesome-icon class="icon" icon="fa-solid fa-clock" /></h1>
            <div id="price-time-subcontainer">
                <table class="table">
                    <tbody class="table-body">
                        <tr>
                            <td class="table-data">Montag - Freitag</td>
                            <td class="table-data-value">geschlossen</td>
                        </tr>
                        <!--<tr>
                            <td class="table-data">Freitag</td>
                            <td class="table-data-value">14:00 - 18:00</td>
                        </tr>-->
                        <tr>
                            <td class="table-data">Samstag - Sonntag</td>
                            <td class="table-data-value">12:00 - 18:00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="special-time-container">
                <p class="special-time-text">
                    Sonderöffnungszeiten sind für Kindergärten, Schulklassen, sonstige staatliche oder kirchliche Organisationen,
                    sowie Vereine möglich und erfolgen 
                    <RouterLink to="/kontakt" class="higlight-special-time"  @click="setActiveLink('/kontakt')">nur nach vorheriger Absprache</RouterLink> 
                    mit der Geschäftsleitung.
                </p>
            </div>
            <div id="hint-container">
                <p>* Die Zeiten können an Feiertagen und heißen Sommertagen abweichen!</p>
            </div>
        </article>
        <article class="price-time-container">
            <h1 class="price-time-title">Preise<font-awesome-icon class="icon" icon="fa-solid fa-euro" /></h1>
            <div id="price-time-subcontainer">
                <table class="table">
                    <tbody class="table-body">
                        <tr>
                            <td class="table-data">Kleinkinder (0-2 Jahre)</td>
                            <td class="table-data-value">freier Eintritt</td>
                        </tr>
                        <tr>
                            <td class="table-data">Kinder (ab 2 Jahren)</td>
                            <td class="table-data-value">10,00 Euro p.P.</td>
                        </tr>
                        <tr>
                            <td class="table-data">Erwachsene</td>
                            <td class="table-data-value">3,00 Euro p.P.</td>
                        </tr>
                        <tr>
                            <td class="table-data">Schwerbehinderte (gegen Ausweis)</td>
                            <td class="table-data-value">50% Ermäßigung p.P.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="special-offers-container">
                <p>
                    Seid ihr eine 
                    <RouterLink to="/gruppen" @click="setActiveLink('/gruppen')">Gruppe</RouterLink> 
                    oder feiert ihr 
                    <RouterLink to="/geburtstage"  @click="setActiveLink('/geburtstage')">Geburtstag?</RouterLink>
                </p>
            </div>
        </article>
    </section>
</template>

<script>
import anime from 'animejs';

    export default {
        name: "PriceTimePage",
        methods: {
            setActiveLink(path) {
                this.$store.commit("setActiveLink", { name: path, path: path });
            }
        },
        mounted() {
            anime({
                targets: '.price-time-title',
                opacity: [0, 1],
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '.table-body',
                opacity: [0, 1],
                delay: 500,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '.special-time-text',
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '#hint-container',
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '#special-offers-container',
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInQuad',
            })
        }
    }
</script>

<style>
    #price-time-page {
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    .price-time-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 6rem 2rem 0 2rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 40%;
        height: 30rem;
    }
    .price-time-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        padding-top: 1.2rem;
    }
    #price-time-subcontainer {
        display: flex;
        flex-direction: row;
        margin: 1.5rem auto 0 auto;
    }
    .table {
        width: 100%;
        border-collapse: collapse;
    }
    .table-data {
        padding: 1rem 2.5rem 1rem 2.5rem;
        text-align: center;
        font-size: 1.3rem;
        font-family: 'Baloo', sans-serif;
    }
    .table-data-value {
        font-family: 'Poppins', sans-serif;
        font-size: 1.2rem;
        padding: 1rem 2.5rem 1rem 2.5rem;
    }
    #special-time-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2.5rem auto; /* before: 1.5rem auto*/
        width: 75%;
    }
    .special-time-text {
        font-family:'Poppins', sans-serif;
        font-weight: 400;
        font-size: 1rem;
    }
    .higlight-special-time {
        text-decoration: underline;
        color: #0077B6;
    }
    .icon {
        margin-left: 1rem;
    }
    #hint-container {
        margin: auto 1rem .5rem auto;
        font-size: .8rem;
        font-style: italic;
        font-family: sans-serif
    }
    #special-offers-container {
        margin: 1rem auto;
        font-size: 1rem;
        font-style: italic;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
    }
    @media only screen and (max-width: 1280px) {
        .price-time-container {
            width: 50%;
        }
    }
    @media only screen and (max-width: 1160px) {
        #price-time-page {
            flex-direction: column;
        }
        .price-time-container {
            width: 70%;
            height: auto;
        }
        .price-time-container:first-of-type {
            margin-top: 7.5rem;
        }
        .price-time-container:last-of-type {
            margin: 2rem 0 1.5rem 0;
        }
    }
    @media only screen and (max-width: 768px) {
        .price-time-container {
            width: 90%;
        }
    }
    @media only screen and (max-width: 576px) {
        .price-time-container {
            width: 100%;
        }
        #special-time-container {
            margin-top: 0;
            width: 90%;
        }
        .price-time-title {
            font-size: 2.1rem;
        }
        #special-offers-container {
            margin-top: 0;
            padding: 0 1rem 0 1rem;
        }
    }
</style>