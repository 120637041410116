<template>
    <section id="unknown-page">
        <article id="unknown-container">
            <h1 id="unknown-title">Diese Seite existiert leider nicht!</h1>
            <button id="unknown-button"><RouterLink id="start-link" to="/">Zurück zum Start</RouterLink></button>
        </article>
    </section>
</template>

<script>
    export default {
        name: "AttractionPage",
    }
</script>

<style>
    #unknown-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #unknown-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
    }
    #unknown-title {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 3rem;
        letter-spacing: 2px;
        margin-bottom: 0;
        padding-top: 1.2rem;
    }
    #unknown-button {
        width: 20%;
        text-align: center;
        margin: 2rem 0 2rem 0;
        height: 4rem;
        text-transform: uppercase;
        background-color: #0077B6;
        color: #FFD700;
        cursor: pointer;
        font-family: 'Baloo', sans-serif;
        font-size: 1.5rem;
        border: none;
    }
    #unknown-button:hover {
        scale: 1.1;
        transition: 0.3s ease;
    }
</style>