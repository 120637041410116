<template>
    <LMap :center="center" :zoom="mapZoom">
        <LTileLayer :url="url" :attribution='attribution' />
        <LMarker :lat-lng="marker">
            <LTooltip :options="{ permanent: true }">
                <div>
                    <p><strong>Fabulas Zauberwelt</strong></p>
                    <p>Unterer Hammer 9</p>
                    <p>64720 Michelstadt</p>
                </div>
            </LTooltip>
        </LMarker>
    </LMap>
</template>

<script>
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet"

export default {
    name: "OpenStreetMap",
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LTooltip,
    },
    data() {
        return {
            center: [49.694800, 9.005820],
            mapZoom: 15,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            marker: [49.694800, 9.005820],
        }
    },
}
</script>