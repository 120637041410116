<template>
    <section id="group-page">
        <article id="group-container">
            <h1 id="group-title">Gruppen<font-awesome-icon class="icon" icon="fa-solid fa-people-group"/></h1>
            <h3 id="group-subtitle">
                Seid ihr eine Gruppe mit <span class="word-highlight">mindestens 15 Kindern?</span>
                Dann gilt folgendes für Euch!
            </h3>
            <p id="description-text">
                Bis zu 2 Erwachsene Begleitpersonen haben <span id="highlight-description-text">freien Eintritt</span>, für jede weitere Begleitpersonen gelten folgende Preise:
            </p>
            <table id="group-price-table">
                <tbody ref="table">
                    <tr>
                        <td class="table-data">Kleinkinder (0-2 Jahre)</td>
                        <td class="table-data-value">freier Eintritt</td>
                    </tr>
                    <tr>
                        <td class="table-data">Kinder (ab 2 Jahren)</td>
                        <td class="table-data-value">7,00 Euro p.P.</td>
                    </tr>
                    <tr>
                        <td class="table-data">Erwachsene</td>
                        <td class="table-data-value">3,00 Euro p.P.</td>
                    </tr>
                    <tr>
                        <td class="table-data">Schwerbehinderte (gegen Ausweis)</td>
                        <td class="table-data-value">50% Ermäßigung p.P.</td>
                    </tr>
                </tbody>
            </table>
        </article>
    </section>
</template>

<script>
import anime from 'animejs';

    export default {
        name: "GroupPage",
        mounted() {
            anime({
                targets: '#group-title',
                opacity: [0, 1],
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '#group-subtitle',
                opacity: [0, 1],
                delay: 500,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '#description-text',
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: this.$refs.table,
                opacity: [0, 1],
                delay: 1200,
                duration: 500,
                easing: 'easeInQuad',
            })
        }
    }
    
</script>

<style>
    #group-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #group-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        height: 100%;
    }
    #group-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1rem;
        padding-top: 1.2rem;
    }
    #group-subtitle {
        font-size: 1.6rem;
        margin-bottom: 0;
        font-family: 'Bold Poppins', sans-serif;
        padding: 0 1rem 0 1rem;
    }
    #group-price-table {
        border-collapse: collapse;
        margin-bottom: 2rem;
    }
    #description-text {
        font-size: 1.25rem;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 2rem;
        padding: 0 3rem 0 3rem;
    }
    #highlight-description-text {
        font-weight: 600;
    }
    @media only screen and (max-width: 768px) {
        #group-container {
            width: 90%;
        }
        #group-subtitle {
            padding: 0 3rem 0 3rem;
            margin-bottom: 1rem;
        }
    }
    @media only screen and (max-width: 576px) {
        #group-container {
            width: 100%;
        }
        #group-price-table {
            width: auto;
        }
        .table-data {
            display: block;
            padding: 0;
            width: 100%;
        }
        .table-data-value {
            display: block;
            padding: 0 0 2rem 0;
            width: 100%;
        }
        #group-title {
            font-size: 2.1rem;
        }
        #group-subtitle {
            margin: 1rem 0 1rem 0;
            padding: 0 1.5rem .0 1.5rem;
            font-size: 1.4rem;
        }
    }
</style>