<template>
    <vueper-slides 
        id="slides-container" 
        autoplay
        fade
        lazy lazy-load-on-drag
        :pause-on-hover="true"
        :bullets="false" >
        <vueper-slide
          v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
            class="slide" >
        </vueper-slide>
    </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

    export default {
        name: "ImageSlider",
        components: {
            VueperSlide,
            VueperSlides,
        },
        data() {
            return {
                slides: [{ image: require("../assets/startPic1.png") }, { image: require("../assets/startPic2.png") } ]
            }
        }
    }

</script>

<style>
    #slides-container {
        width: 80%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 2rem;
    }
    .slide {
        width: 100%;
    }
    @media only screen and (max-width: 690px) {
        #slides-container {
            width: 99%;
        }
    }
</style>