<template>
    <section id="about-page">
        <article id="about-container">
            <h1 id="about-title">Wer sind wir?</h1>
            <h3 id="about-subtitle">Willkommen bei <span class="word-highlight">Fabulas Zauberwelt</span></h3>
            <p class="about-text">
                Wir sind mehr als nur ein Spielplatz - wir sind ein magischer Ort, an dem Kinder Träume leben und Abenteuer erleben können. 
                Bei uns dreht sich alles um Lächeln, Lachen und grenzenlosen Spielspaß!
            </p>
            <p class="about-text">
                Unser Indoor-Kinderspielplatz ist ein wahres Wunderland für junge Abenteurer. 
                Wir bieten eine Vielzahl von Spielbereichen, von kletterfreudigen Rutschen über schaukelnde Seilbrücken bis hin zu Ballpools, 
                in denen die Kleinen ihre Energie entfesseln können.
            </p>
            <p class="about-text">
                Danke, dass du uns auf unserer Reise begleitest! 
                Wir freuen uns darauf, eure Kinder wachsen zu sehen und ein Lächeln auf ihre Gesichter zu zaubern. 
                Besucht uns bald, um euren eigenen Teil unserer zauberhaften Welt zu erleben!
            </p>
            <div id="instagram-container">
                <p id="instagram-text">Besuche uns gerne auf Instagram für mehr!</p>
                <a id="instagram-icon-wrapper" href="https://www.instagram.com/fabulaszauberwelt/"><font-awesome-icon id="instagram-icon" icon="fa-brands fa-instagram" /></a>
            </div>
        </article>
    </section>
</template>

<script>
import anime from 'animejs';

    export default {
        name: "AboutPage",
        mounted() {
            anime({
                targets: '#about-title',
                opacity: [0, 1],
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '#about-subtitle',
                opacity: [0, 1],
                delay: 500,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '.about-text',
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInQuad',
            })
            anime({
                targets: '#instagram-container',
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInQuad',
            })
        }
    }
</script>

<style>
    #about-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background-image: url('../assets/background.jpg');
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: center;
    }
    #about-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        height: 100%;
    }
    #about-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1rem;
        padding-top: 1.2rem;
    }
    #about-subtitle {
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        font-family: 'Bold Poppins', sans-serif;
    }
    .about-text {
        font-size: 1.25rem;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 2rem;
        padding: 0 3rem 0 3rem;
    }
    #instagram-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #instagram-text {
        font-size: 1.3rem;
        margin-bottom: .5rem;
        font-family: 'Baloo', sans-serif;
    }
    #instagram-icon-wrapper {
        color: #9900CC;
    }
    #instagram-icon {
        margin-bottom: 2rem;
        font-size: 2.5rem;
    }
    #instagram-icon-wrapper:hover {
        transition: 0.3s ease-in-out;
        scale: 1.1;
    }
    @media only screen and (max-width: 768px) {
        #about-container {
            width: 90%;
        }
    }
    @media only screen and (max-width: 576px) {
        #about-container {
            width: 100%;
        }
        .about-text:first-of-type {
            padding-top: 1rem;
        }
        #about-subtitle {
            margin-bottom: 0;
            padding: 0 1rem 0 1rem;
            font-size: 1.4rem;
        }
        #about-title {
            font-size: 2.1rem;
        }
    }
</style>