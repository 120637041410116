<template>
    <div id="button-container" v-show="showButton" @click="goToTop">
        <button id="to-top-button">
            <font-awesome-icon id="top-button-icon" icon="fa-solid fa-arrow-up"/>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ScrollToTop',
    data() {
        return {
            showButton: false,
        }
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 100) {
                this.showButton = true;
            } else {
                this.showButton = false;
            }
        },
        goToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style>
    #button-container {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 1.5rem;
    }
    #to-top-button {
        display: none;
        border-radius: 50%;
        border: none;
        font-size: 1.5rem;
        background-color: #0077B6;
        height: 4rem;
        width: 4rem;
        color: white;
        cursor: pointer;
    }
    #top-button-icon {
        margin: auto;
    }
    @media only screen and (max-width: 576px) {
        #to-top-button {
            display: block;
        }
    }
</style>