<template>
    <section id="condition-page">
        <article id="condition-container">
            <h1 id="condition-title">Allgemeine Geschäftsbedingungen</h1>
            <div id="condition-text-container">
                <p class="condition-text">
                    Werte Gäste, mit dem Lösen der Eintrittskarte erkennen Sie die allgemeinen Geschäftsbedingungen und die Hallenordnung an.
                </p>
                <p class="condition-text">
                    Die jeweils gültigen Preise und Öffnungszeiten sind dem Aushang an der Kasse und unseren
                    Informationsblättern zu entnehmen. Die Kassenschlusszeiten und die Hinweise unseres
                    Personals zum Ende des Hallenbetriebes sind zu beachten. Gelöste Eintrittskarten werden
                    nicht zurückgenommen und bei Verlust nicht ersetzt.
                </p>
                <p class="condition-text">
                    Die Spielgeräte dürfen aus Hygienegründen nur mit Socken betreten werden.
                    Die Halle ist mit einem Feuer- und Rauchfrühwamsystem ausgestattet. Daher ist in der Halle,
                    sowie auf den Toiletten das Rauchen streng verboten Nur in dem dafür vorgesehenen und
                    gekennzeichneten Bereich erlaubt. Zuwiderhandeln kann Fehlalarm
                    auslösen. Die Kosten dafür trägt der Verursacher.
                </p>
                <p class="condition-text">
                    Unsere Gastronomie mit Selbstbedienung bietet Ihnen ein reichhaltiges Angebot an Speisen
                    und Getränken. Das Mitbringen von Speisen und Getränken ist nicht gestattet. Behälter aus Glas
                    oder Porzellan dürfen nicht in den Spielbereich gebracht werden.
                </p>
                <p class="condition-text">
                    Unsere Halle dient dem Sport und Spiel, was Umsicht und gegenseitige Rücksichtnahme
                    gegenüber anderen Gästen erfordert. Der Betreiber übernimmt keine Betreuungspflicht der
                    Kinder. Diese liegt ausschließlich bei der/den Begleitpersonen/en. Wir machen die
                    Begleitpersonen darauf aufmerksam, ihre Betreuungspflicht auch wirklich auszuüben.
                    Kinder ab 8 Jahren dürfen die Halle ohne Begleitung eines Erwachsenen besuchen, diese benötige
                    eine schriftliche Zustimmung der Eltern. Kinder unter 8 Jahren können die Halle nur in Begleitung
                    eines Erwachsenen besuchen.
                </p>
                <p class="condition-text">
                    Wird die zugelassene Besucherzahl überschritten, so kann der Betreiber oder das zustandige
                    Personal den Zutritt weiterer Besucher einschränken. Mit Wartezeiten ist dann zu rechnen.
                    Der allgemeine Hallenbetrieb oder Teile der Anlage können zeitweise eingeschränkt werden.
                    Ansprüche gegen den Betreiber aus diesen Einschränkungen sind ausgeschlossen.
                </p>
                <p class="condition-text">
                    Die Nutzung sämtlicher Betriebsteile und Einrichtungen erfolgt auf eigene Gefahr,
                    unbeschadet der Verpflichtung des Betreibers die Einrichtung in einem gebrauchsfähigen und
                    sicheren Zustand zu erhalten. Der Betreiber und seine Mitarbeiter haften nur für Vorsatz und
                    grobe Fahrlässigkeit. Die Benutzung von Garderobe geschieht auf eigenes Risiko.
                    Für abhanden gekommene Gegenstände und Kleidung, auch aus geschlossenen Behältnissen
                    oder auf dem Parkplatz, übernimmt der Betreiber keine Haftung.
                    Fundsachen werden entsprechend an der Kasse aufbewahrt und bei Nichtabholung nach
                    gesetzlichen Bedingungen behandelt.
                    Wenn Besucher bei der Benutzung aller Einrichtungen der Halle, wie z.B. der Spiel- und
                    Sportgeräte, Sprunganlagen und Rutschen durch eigene Unachtsamkeit Schäden an Geräten
                    oder anderen Gästen verursachen, haften sie für diese.
                </p>
                <p class="condition-text">
                    Den Anweisungen des Aufsichtspersonals in der Halle ist unbedingt Folge zu leisten.
                    Besucher, die gegen die Grundsätze der Hallenordung handeln oder Anweisungen nicht
                    beachten, können im Einzelfall zeitlich begrenzt oder auch dauerhaft von der Benutzung
                    ausgeschlossen werden. Der Eintritt wird in diesem Fall nicht erstattet.
                    Alle Anlagen und Einrichtungen der Halle dürfen nur entsprechend ihrer Zweckbestimmung benutzt werden.
                    Das Großtrampolin darf jeweils nur von einer Person/Sprungtuch benutzt werden.
                </p>
                <p class="condition-text">
                    Kinder unter 3 Jahren dürfen wegen Verletzungsgefahr die Großtrampolinanlage nicht benutzen.
                    Im Interesse aller Gäste behält sich der Betreiber vor, Personen, deren Zulassung zum
                    Hallenbesuch bedenklich erscheint (z.B. erkennbar alkoholisiert etc.), den Zutritt ohne Angabe von Gründen zu verwehren.
                    Geben Sie vor Verlassen der Halle ausgeliehene Sachen bitte wieder an der Kasse zurück. Bei
                    Beschädigung oder Verlust ist Ersatz zu leisten. Filmen und Fotografieren ist in unserer Halle
                    erlaubt. Fremde Personen dürfen nur mit deren Zustimmung aufgenommen werden. Für
                    gewerbliche Zwecke und für die Presse bedarf das Fotografieren einer vorherigen
                    Genehmigung durch die Betreibergesellschaft. Wir behalten uns das Recht vor Bilder der Gäste
                    für eigene Werbezwecke zu nutzen. Der Besucher erlaubt dies mit Besuch der Parks ausdrücklich.
                </p>
                <p class="condition-text">
                    Die Benutzung der Parkplätze erfolgt auf eigene Gefahr. Der Betreiber ist weder gehalten
                    Parkplätze zu bewachen noch ihre Flächen und sonstigen Einrichtungen zu warten, um die Fahrzeuge vor Schäden (z.B. Glasscherben, Nägel oder ähnliches) zu bewahren.
                </p>
            </div>
        </article>
    </section>
</template>

<script>
    export default {
        name: "ConditionPage",
    }
</script>

<style>
    #condition-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        width: 100%;
        background: linear-gradient(to bottom, #87CEEB, #B9DFF1);
        justify-content: center;
        align-items: center;
    }
    #condition-title {
        font-size: 2.5rem;
        letter-spacing: 2px;
        color: #0077B6;
        justify-self: center;
        align-self: center;
        border-bottom: #0077B6 solid 2px;
        width: 90%;
        margin-bottom: 1rem;
        padding-top: 1.2rem;
    }
    #condition-container {
        display: flex;
        flex-direction: column;
        justify-content: left;
        left: 0;
        margin-top: 7rem;
        margin-bottom: 1rem;
        background-color: rgba(249, 239, 239, 0.7);
        width: 70%;
        height: 100%;
    }
    #condition-text-container {
        font-family: 'Poppins', sans-serif;
        line-height: 1.5;
    }
    .condition-text {
        padding: 0 3rem 0 3rem;
        margin-top: 2rem;
        overflow: hidden;
    }
    .condition-text:last-of-type {
        margin-bottom: 2rem;
    }
    @media only screen and (max-width: 768px) {
        #condition-container {
            width: 90%;
        }
    }
    @media only screen and (max-width: 576px) {
        #condition-container {
            width: 100%;
        }
        #condition-title {
            font-size: 1.9rem;
        }
    }
</style>